.notfound-container {
    width: 100vw;
    height: 100vh;
    background-color: #00197D;

    display: flex;
    justify-content: center;
    align-items: center;
}

.notfound-container h1 {
    font: 400 24px Montserrat, sans-serif;
    color: #ffffff;
}

@media (max-width: 1200px) {
    .notfound-container h1 {
        font-size: 36px;
    }
}

@media (max-width: 1000px) {
    .notfound-container h1 {
        font-size: 32px;
    }
}

@media (max-width: 800px) {
    .notfound-container h1 {
        font-size: 28px;
    }
}
