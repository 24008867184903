@import url('https://fonts.googleapis.com/css?family=Montserrat:500&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    font-family: Montserrat, sans-serif;
    background: #f0f0f5;
    -webkit-font-smoothing: antialiased;
}
