@import url(https://fonts.googleapis.com/css?family=Montserrat:500&display=swap);
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    font-family: Montserrat, sans-serif;
    background: #f0f0f5;
    -webkit-font-smoothing: antialiased;
}

.lessonpack-container {
    width: 100%;
    height: 100%;

    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
}

.header-container {
    width: 100%;
    height: 180px;
    background:#00197D;

    display: flex;
}

#title {
    font: 400 34px Montserrat, sans-serif;
    color: #ffffff;

    align-self: flex-end;

    margin-left: 32px;
    margin-bottom: 16px;
}

@media (max-width: 1200px) {
    .header-container {
        height: 175px;
    }

    #title {
        font-size: 32px;
    }
}

@media (max-width: 1000px) {
    .header-container {
        height: 170px;
    }

    #title {
        font-size: 30px;
    }
}

@media (max-width: 800px) {
    .header-container {
        height: 165px;
    }

    #title {
        font-size: 28px;
    }
}

.lesson-grid {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    justify-items: center;
    align-items: center;
}

@media (max-width: 1200px) {
    .lesson-grid {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 24px;
    }
}

@media (max-width: 1000px) {
    .lesson-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 40px;
    }
}

@media (max-width: 800px) {
    .lesson-grid {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 40px;
    }
}

.lesson-item-container {
    width: 272px;
    height: 272px;
    padding: 12px;
    background: #ffffff;
    border: none;

    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}

#lesson-name {
    font: 16px Montserrat, sans-serif;
    color: #191B21;
}

#card-number {
    font: 16px Montserrat, sans-serif;
    color: #545454;
}

.lesson-item-container:hover {
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
    cursor: pointer;
}

@media (max-width: 1200px) {
    .lesson-item-container {
        width: 296px;
        height: 296px;
    }

    #lesson-name {
        font-size: 17px;
    }

    #card-number {
        font-size: 17px;
    }
}

@media (max-width: 1000px) {
    .lesson-item-container {
        width: 340px;
        height: 340px;
    }

    #lesson-name {
        font-size: 18px;
    }

    #card-number {
        font-size: 18px;
    }
}

@media (max-width: 800px) {
    .lesson-item-container {
        width: 360px;
        height: 360px;
    }

    #lesson-name {
        font-size: 20px;
    }

    #card-number {
        font-size: 20px;
    }
}

.card-container {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
}

.card-item-container {
    width: 272px;
    height: 272px;
    padding: 12px;
    background: #ffffff;
    border: none;

    text-align: center;
    font: 16px Montserrat, sans-serif;
    color: #191B21;

    display: flex;
    align-items: center;
    justify-content: center;
}

.card-item-container:hover {
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
    cursor: pointer;
}

@media (max-width: 1200px) {
    .card-item-container {
        width: 296px;
        height: 296px;
        
        font-size: 17px;
    }
}

@media (max-width: 1000px) {
    .card-item-container {
        width: 340px;
        height: 340px;

        font-size: 18px;
    }
}

@media (max-width: 800px) {
    .card-item-container {
        width: 360px;
        height: 360px;

        font-size: 20px;
    }
}

.card-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 32px;
    justify-items: center;
    align-items: center;
}

@media (max-width: 1200px) {
    .card-grid {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 24px;
    }
}

@media (max-width: 1000px) {
    .card-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 40px;
    }
}

@media (max-width: 800px) {
    .card-grid {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 40px;
    }
}

#back {
    font: 400 20px Montserrat, sans-serif;
    color: white;
    background: none;
    border: none;
    
    margin-left: 24px;
    margin-right: 24px;

    padding: 8px;

    position: absolute;
    z-index: 2;
}

#back:hover {
    background-color: rgba(204, 204, 204, 0.75);
    cursor: pointer;
}

.page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #00197D;
}

.interaction-container {
    width: 100%;
    /*16px its the size of the progress bar*/
    height: calc(100vh - 16px);
    background-color: #00197D;
    display: flex;
    justify-content: center;
    align-items: center;
}

#back {
    font: 400 20px Montserrat, sans-serif;
    color: white;
    background: none;
    border: none;

    margin-top: 4px;
    padding: 8px;

    position: absolute;
    z-index: 2;
}

.progressbar {
    height: 16px;
    background-color: white;
}

.container {
    width: 100%;
    height: 100%;

    background-color: #00197D;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.text-container {
    width: 100%;
    flex: 1 1;
    display: flex;
    align-items: center;

    padding-left: 17%;
    padding-right: 17%;
}

.text-container h1 {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    font: 400 24px Montserrat, sans-serif;
    color: #ffffff;
}

.button-container {
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    display: flex;
    justify-content: space-between;
}

.button-container button {
    width: 100%;
    height: 80px;
    
    font: 400 24px Montserrat, sans-serif;
    text-transform: uppercase;
    background-color: #ffffff;
    color: black;
    border: none;
}

#previous {
    width: 50%;
}

#next {
    width: 50%;
}

#previous:hover {
    cursor: pointer;
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
}

#next:hover {
    cursor: pointer;
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
}

@media (max-width: 1200px) {
    .text-container h1 {
        font-size: 22px;
    }

    .button-container button {
        height: 77px;
        font-size: 22px;
    }
}

@media (max-width: 1000px) {
    .text-container h1 {
        font-size: 20px;
    }

    .button-container button {
        height: 74px;
        font-size: 20px;
    }
}

@media (max-width: 800px) {
    .text-container h1 {
        font-size: 18px;
    }

    .button-container button {
        height: 71px;
        font-size: 18px;
    }
}

.question-container {
    width: 70%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 1;
}

#question {
    font: 400 24px Montserrat, sans-serif;
    color: #ffffff;
}

.answer-container {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
}

.answer-container button {
    margin-top: 15px;
}

#answer {
    width: 580px;
    height: 105px;
    outline: none;
    border: none;

    padding-left: 12px;
    padding-right: 12px;

    font: 400 16px Montserrat, sans-serif;
    background-color: #ffffff;
    color: #191B21;
}

#answer:hover {
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
    cursor: pointer;
}

.question-container #toast {
    width: 100%;
    height: 0px;
    background-color: #191B21;

    font: 400 16px Montserrat, sans-serif;
    color: white;

    position: fixed;
    z-index: 2;
    bottom: 0;

    transition: height .2s ease-in;
}

@media (max-width: 1200px) {
    #question {
        font-size: 22px;
    }

    #answer {
        width: 548px;
        height: 103px;

        font-size: 15px;
    }
}

@media (max-width: 1000px) {
    #question {
        font-size: 20px;
    }

    #answer {
        width: 516px;
        height: 101px;

        font-size: 14px;
    }
}

@media (max-width: 800px) {
    #question {
        font-size: 18px;
    }

    #answer {
        width: 464px;
        height: 98px;

        font-size: 13px;
    }
}

@media (max-width: 500px) {
    #question {
        font-size: 18px;
    }

    #answer {
        width: 360px;
        height: 90px;

        font-size: 13px;
    }
}

.text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-container h1 {
    color: #ffffff;
    font: 400 24px Montserrat, sans-serif;
}

.notfound-container {
    width: 100vw;
    height: 100vh;
    background-color: #00197D;

    display: flex;
    justify-content: center;
    align-items: center;
}

.notfound-container h1 {
    font: 400 24px Montserrat, sans-serif;
    color: #ffffff;
}

@media (max-width: 1200px) {
    .notfound-container h1 {
        font-size: 36px;
    }
}

@media (max-width: 1000px) {
    .notfound-container h1 {
        font-size: 32px;
    }
}

@media (max-width: 800px) {
    .notfound-container h1 {
        font-size: 28px;
    }
}

